import { Link, useParams } from "@remix-run/react";
import { Button, Flex, Heading, Image, Text } from "sparta";
import fallback from "~/assets/fallback.png";
import type { Post } from "~/sdk/qs1/index.server";
import useBreakpoint from "~/utils/hooks/useBreakpoint";

interface ILargeModule {
  article: Post;
}

export default function LargeModule({ article }: ILargeModule) {
  const mobile = useBreakpoint("mobile");
  const params = useParams();
  const platform = params.platform ?? article?.platform?.[0]?.name?.toLowerCase();

  const displayableTitle =
    article?.title?.length > 26 && mobile ? `${article?.title?.slice(0, 26)}...` : article?.title;
  const displayablePreview =
    (article?.previewContent?.length ?? 0) > 35 && mobile
      ? `${article?.previewContent?.slice(0, 35)}...`
      : `${article?.previewContent}...`;

  return (
    <Image
      src={article?.thumbnail?.url?.length ? article?.thumbnail?.url : fallback}
      alt={article?.title}
      width="100%"
      height="100%"
      className="large-module"
      center
    >
      <Flex direction="column" height="100%">
        <Flex flexGrow="1" />
        <div className="large-module-desc">
          <Text bold>{article?.category}</Text>
          <Heading
            as="h2"
            style={{
              maxHeight: mobile ? "28.5px" : undefined,
              overflow: "hidden",
            }}
          >
            {displayableTitle}
          </Heading>
          <Text
            style={{
              maxHeight: mobile ? "19px" : undefined,
              overflow: "hidden",
            }}
          >
            {displayablePreview}
          </Text>
          <div className="read-more">
            <Link to={`/${platform}/posts/${article?.slug}`}>
              <Button>Read More</Button>
            </Link>
          </div>
        </div>
      </Flex>
    </Image>
  );
}
